import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import env from '../../env';
import FluidImg from './FluidImg';
import imageSizes from '../Cards/image-sizes';
import './ProductCover.scss';

export default function ProductCover({
  alt,
  cover, // 16x9 cover
  portraitCover, // 2x3 cover
  coverType, // What type of cover has been uploaded? 16x9 (Standard), Portrait or Square
  displayType,
  dpr,
  lazy,
  folder,
  hitcard,
  link,
  sizesOverride,
  thumbnail,
  fantasyVerse,
  force16by9, // To be used when portrait and square images need to be shown at 16x9, e.g. in the featured carousel
}) {
  let coverImageSize = 'standard';
  if (displayType === 'comic' || displayType === 'graphic-novel' || displayType === 'book' || displayType === 'audio' || displayType === 'video' || displayType === 'software') {
    coverImageSize = 'portrait';
  }
  if (coverType === '16x9') {
    coverImageSize = 'standard';
  }
  if (coverType === 'square') {
    coverImageSize = 'square';
  }
  if (coverType === 'portrait') {
    coverImageSize = 'portrait';
  }

  const renderCover = () => {
    if (portraitCover && coverImageSize === 'portrait' && !force16by9) {
      return (
        <div className={`responsive-image-island product-cover-container ${coverImageSize}-cover`}>
          <div className="responsive-image responsive-image--2by3">
            <FluidImg
              className="portrait-image"
              src={portraitCover}
              lazy={lazy}
              alt={alt}
              folder={folder}
            />
          </div>
        </div>
      );
    }

    return (
      <div className={classNames('responsive-image-island product-cover-container', `${coverImageSize}-cover`, { 'thumbnail-cover': thumbnail }, { 'hitcard-cover': hitcard && coverImageSize === 'standard' })}>
        <div className={classNames('responsive-image', { 'responsive-image--16by9': coverImageSize === 'standard' || force16by9 || !fantasyVerse }, { 'responsive-image--1by1': coverImageSize === 'square' && fantasyVerse && !force16by9 }, { 'responsive-image--2by3': coverImageSize === 'portrait' && fantasyVerse && !force16by9 })}>
          {cover &&
            <React.Fragment>
              {cover.startsWith('facet/') ?
                <img className="img-force-full" alt={alt} src={`${env.apiProtocol}://${env.apiUrl}/image/${cover}`} />
                :
                <React.Fragment>
                  {coverImageSize === 'standard' &&
                    <FluidImg
                      ratio16by9
                      sizesOverride={sizesOverride}
                      className="img-force-full"
                      src={cover}
                      alt={alt}
                      dpr={dpr}
                      lazy={lazy}
                      folder={folder}
                    />
                  }

                  {coverImageSize === 'square' &&
                    <React.Fragment>
                      <FluidImg
                        className="cover-image-foreground"
                        src={cover}
                        alt={alt}
                        dpr={dpr}
                        lazy={lazy}
                        folder={folder}
                      />
                      {fantasyVerse &&
                        <FluidImg
                          className="cover-image-background"
                          src={cover}
                          alt="Blurred background image"
                          lazy={lazy}
                          folder={folder}
                        />
                      }
                    </React.Fragment>
                  }

                  {coverImageSize === 'portrait' &&
                    <React.Fragment>
                      <FluidImg
                        className="portrait-image"
                        src={cover}
                        lazy={lazy}
                        alt={alt}
                        folder={folder}
                      />
                      <FluidImg
                        className="portrait-image-background"
                        src={cover}
                        alt="Blurred background image"
                        lazy={lazy}
                        folder={folder}
                      />
                    </React.Fragment>
                  }
                </React.Fragment>
              }
            </React.Fragment>
          }
        </div>
      </div>
    );
  };

  if (link) {
    return (
      <Link to={link}>
        {renderCover()}
      </Link>
    );
  }

  return (
    renderCover()
  );
}

ProductCover.propTypes = {
  alt: PropTypes.string,
  cover: PropTypes.string,
  coverType: PropTypes.string,
  displayType: PropTypes.string,
  dpr: PropTypes.number,
  fantasyVerse: PropTypes.bool,
  folder: PropTypes.string,
  force16by9: PropTypes.bool,
  hitcard: PropTypes.bool,
  lazy: PropTypes.bool,
  link: PropTypes.string,
  portraitCover: PropTypes.string,
  sizesOverride: PropTypes.string,
  thumbnail: PropTypes.bool,
};

ProductCover.defaultProps = {
  alt: '',
  cover: '',
  coverType: '',
  displayType: '',
  dpr: 1,
  fantasyVerse: false,
  folder: 'product',
  force16by9: false,
  hitcard: false,
  lazy: false,
  link: null,
  portraitCover: '',
  sizesOverride: imageSizes(3, 4, 4, 6),
  thumbnail: false,
};
